import './App.scss';
import React from 'react';
import MyThree from './components/MyComponent';

function App() {
  return (
    <div className="App">
      <MyThree />
      
    </div>
  );
} 

export default App;
